
import { useState, useEffect } from "react";
import useFormContext from "../hooks/useFormContext";
import NextButton from "../components/NextButton";
import PrevButton from "../components/PrevButton";

const Step6 = () => {
  const { data, handleChange } = useFormContext();
  
  // States for controlling the visibility of options
  const [showStationaryOptions, setShowStationaryOptions] = useState(false);
  const [showKapseleOption, setShowKapseleOption] = useState(false);

  // Effect hook to update the visibility based on the selected option
  useEffect(() => {
    setShowStationaryOptions(data.robotWithMobile === "STATIONÄR");
    setShowKapseleOption(data.robotWithMobile === "Mobil");
  }, [data.robotWithMobile]);

  const handleRobotMobileChange = (e) => {
    handleChange(e);
    const selectedValue = e.target.value;

    setShowStationaryOptions(selectedValue === "STATIONÄR");
    setShowKapseleOption(selectedValue === "Mobil");
  };

  return (
    <div className="wh2-form-main-container">
      <div className="wh2-form-input">
        <label>Worauf soll der Roboter montiert werden?</label>
        <div className="wh2-form-input-row">
          <div>
            <input
              type="radio"
              id="robotWithMobile1"
              name="robotWithMobile"
              value="Mobil"
              checked={data.robotWithMobile === "Mobil"}
              onChange={handleRobotMobileChange}
            />
            <label htmlFor="robotWithMobile1">Mobil</label>
          </div>
          <div>
            <input
              type="radio"
              id="robotWithMobile2"
              name="robotWithMobile"
              value="STATIONÄR"
              checked={data.robotWithMobile === "STATIONÄR"}
              onChange={handleRobotMobileChange}
            />
            <label htmlFor="robotWithMobile2">STATIONÄR</label>
          </div>
        </div>
      </div>

      {showKapseleOption && (
        <div className="wh2-form-input">
          <div className="wh2-form-input-row">
            <div>
              <input
                type="radio"
                id="kapseleOption"
                name="kapseleOption"
                value="Kapsele"
                checked={data.kapseleOption === "Kapsele"}
                onChange={handleChange}
              />
              <label htmlFor="kapseleOption">Käpsele</label>
            </div>
          </div>
        </div>
      )}

      {showStationaryOptions && (
        <div className="wh2-form-input">
          <div className="wh2-form-input-row">
            <div>
              <input
                type="radio"
                id="stationaryOption1"
                name="stationaryOption"
                value="Slim"
                checked={data.stationaryOption === "Slim"}
                onChange={handleChange}
              />
              <label htmlFor="stationaryOption1">Slim</label>
            </div>
            <div>
              <input
                type="radio"
                id="stationaryOption2"
                name="stationaryOption"
                value="Integrated"
                checked={data.stationaryOption === "Integrated"}
                onChange={handleChange}
              />
              <label htmlFor="stationaryOption2">Integrated</label>
            </div>
          </div>
        </div>
      )}

      <div className="wh2-form-page-buttons">
        <PrevButton />
        <NextButton />
      </div>
    </div>
  );
};

export default Step6;
