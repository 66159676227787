import useFormContext from "../hooks/useFormContext";
import weiterArrow from "../assets/weiter-arrow.svg";

const NextButton = () => {
  const { disableNext, handleNext } = useFormContext();

  
  return (
    <button
      className="wh2-form-next-button"
      onClick={handleNext}
      disabled={disableNext}
    >
      Weiter <img className="wh2-form-button-img" src={weiterArrow} alt="weiter-arrow" />
    </button>
  );
};

export default NextButton;
