import FormInputs from "./FormInputs";
import useFormContext from "../hooks/useFormContext";
import upArrow from "../assets/wh2-form-up-arrow.svg";
import downArrow from "../assets/wh2-form-down-arrow.svg";
import ThreeDModelViewer from "./ThreeDModelViewer";
import cobotModel from "../assets/3D_CRX_cobot.glb";
import universalRobot from "../assets/universal_without.glb";
import orbitImage from "../assets/kursor.svg";

const titles = [
  "Wie schwer ist dein Bauteil?",
  "EOAT / Werkzeug",
  "Welche Art Greifarm soll es sein?",
  "Roboter mit Kamera?",
  "Zusammenbau von Elementen",
  "Worauf soll der Roboter montiert werden?",
  "Auf welcher Höhe soll der Roboter montiert werden",
  "Was wir auch anbieten",
  "Kontakt",
];

const Form = () => {
  const {
    page,
    setPage,
    data,
    title,
    canSubmit,
    handleNext,
    handlePrev,
    disablePrev,
    disableNext,
    handleSubmit,
    submissionSuccess,
    prevHide,
    nextHide,
    submitHide,
  } = useFormContext();

  const getModelProps = (manufacturer) => {
    switch (manufacturer) {
      case "FANUC":
        return {
          glbUrl: cobotModel,
          customSize: 40,
          customPosition: 10,
        };
      case "Universal Robots":
        return {
          glbUrl: universalRobot,
          customSize: 40,
          customPosition: 0,
        };
    }
  };

  const { glbUrl, customSize, customPosition } = getModelProps(data.hersteller);

  let currentPageNumber = page + 1;
  const totalPageCount = titles.length;

  if (currentPageNumber < 1) {
    currentPageNumber = 1;
  } else if (currentPageNumber > totalPageCount) {
    currentPageNumber = totalPageCount;
  }

  const pageNavigation = `${currentPageNumber
    .toString()
    .padStart(2, "0")}/${totalPageCount.toString().padStart(2, "0")}`;

  const content = (
    <form className="wh2-multi-step-form" onSubmit={handleSubmit}>
      <div className="wh2-form-body-container">
        <FormInputs />

        <div className="wh2-form-3d-box">
          <div className="wh2-form-dot-nav">{pageNavigation}</div>

          {glbUrl && (
            <ThreeDModelViewer
              key={glbUrl}
              glbUrl={glbUrl}
              customSize={customSize}
              customPosition={customPosition}
              customBackground={"transparent"}
            />
          )}

          <img className="wh2-form-orbit" src={orbitImage} />
        </div>

        <div className="wh2-form-sidebar">
          <button
            onClick={handlePrev}
            disabled={disablePrev}
            className="wh2-form-arrow-button"
            type="button"
          >
            <img src={upArrow} alt="up-step-arrow" />
          </button>
          <ul className="wh2-form-step-list">
            {titles.map((title, index) => (
              <li
                key={index}
                className={`wh2-form-step-item ${
                  page === index ? "active" : ""
                } ${page > index ? "inactive-step" : ""}`}
              >
                {title}
              </li>
            ))}
          </ul>
          <button
            onClick={handleNext}
            disabled={disableNext}
            className="wh2-form-arrow-button"
            type="button"
          >
            <img src={downArrow} alt="down-step-arrow" />
          </button>
        </div>
      </div>
    </form>
  );

  return content;
};
export default Form;
