import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

function ThreeDModelViewer({
  glbUrl,
  customSize,
  customBackground,
  customPosition,
}) {
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  let isDisposed = false;

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let width = container.offsetWidth;
    let height = container.offsetHeight;

    // Check if the width is less than 768px.
    if (window.innerWidth < 768) {
      // Double the height.
      height *= 1.1;
      customPosition = customPosition + 6;
    } else if (window.innerWidth >= 950 && window.innerWidth < 1360) {
      height *= 0.8;
    } else if (window.innerWidth >= 768 && window.innerWidth < 951) {
      height *= 0.65;
    }

    let camera, scene, renderer;

    init();
    render();

    function init() {
      console.log("init called");
      camera = new THREE.PerspectiveCamera(45, width / height, 1, 2000);
      camera.position.set(0, 0, 100);

      scene = new THREE.Scene();

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0xcccccc, 5);
      hemiLight.position.set(0, 400, 0);
      scene.add(hemiLight);

      const dirLight = new THREE.DirectionalLight(0xffffff, 2.5);
      dirLight.position.set(0, 200, 200);
      dirLight.castShadow = true;
      dirLight.shadow.camera.top = 180;
      dirLight.shadow.camera.bottom = -100;
      dirLight.shadow.camera.left = -120;
      dirLight.shadow.camera.right = 120;
      scene.add(dirLight);

      const dirLight2 = new THREE.DirectionalLight(0xffffee, 1);
      dirLight2.position.set(400, 0, -100);
      dirLight2.castShadow = true;
      dirLight2.shadow.camera.top = 180;
      dirLight2.shadow.camera.bottom = -100;
      dirLight2.shadow.camera.left = -120;
      dirLight2.shadow.camera.right = 120;
      scene.add(dirLight2);

      const dirLight3 = new THREE.DirectionalLight(0xffffff, 0.5);
      dirLight3.position.set(-400, 0, 0);
      dirLight3.castShadow = true;
      dirLight3.shadow.camera.top = 180;
      dirLight3.shadow.camera.bottom = -100;
      dirLight3.shadow.camera.left = -120;
      dirLight3.shadow.camera.right = 120;
      scene.add(dirLight3);

      // Load the GLTF model
      const loader = new GLTFLoader();

      if (customBackground === "transparent") {
        scene.background = null;
      } else {
        scene.background = new THREE.Color().setHex(customBackground);
      }

      loader.load(
        glbUrl,
        function (gltf) {
          gltf.scene.scale.set(customSize, customSize, customSize);
          scene.add(gltf.scene);
          setIsLoading(false); 
          render();
        },
        undefined,
        function (error) {
          console.log("An error happened");
          setIsLoading(false); 
        }
      );

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 1;
      renderer.shadowMap.enabled = true;
      container.appendChild(renderer.domElement);

      if (customBackground === "transparent") {
        scene.background = null;
      } else {
        scene.background = new THREE.Color().setHex(customBackground);
      }

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.addEventListener("change", render);
      controls.enableZoom = false;
      controls.minDistance = 100;
      controls.maxDistance = 500;
      controls.target.set(0, customPosition, -0.2);
      controls.update();

      window.addEventListener("resize", onWindowResize);
    }

    function onWindowResize() {
      width = container.offsetWidth;
      height = container.offsetHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      renderer.setSize(width, height);

      render();
    }

    function render() {
      renderer.render(scene, camera);
    }

    return () => {
      // Clean up resources only if they haven't been disposed of already
      if (!isDisposed) {
        isDisposed = true;

        while (scene.children.length > 0) {
          scene.remove(scene.children[0]);
        }
        if (customBackground === "transparent") {
          scene.background = null;
        } else {
          scene.background = new THREE.Color().setHex(customBackground);
        }

        window.removeEventListener("resize", onWindowResize);
        if (renderer) {
          renderer.dispose();
          renderer.domElement.remove();
        }
      }
    };
  }, [glbUrl, customSize, customBackground, customPosition]);

  return (
    <div ref={containerRef} id="wh2-form-3d">
      {isLoading && (
        <div className="WH2-spinner-box">
          <div className="WH2-circle-border">
            <div className="WH2-circle-core"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ThreeDModelViewer;
