import useFormContext from "../hooks/useFormContext";
import NextButton from "../components/NextButton";
import PrevButton from "../components/PrevButton";
import SkipButton from "./SkipButton";


const Step3 = () => {
  const { data, handleChange } = useFormContext();

  const content = (
    <div className="wh2-form-main-container">
      <div className="wh2-form-input">
        <label>Möchtest du ein EOAT von KILIVATIONS?</label>
        <span className="wh2-big-subtitle">Art des EOAT:</span>
        <div className="wh2-form-input-row">
          <div>
            <input
              type="radio"
              id="typeOfEoats1"
              name="typeOfEoats"
              value="Elektrisch"
              checked={data.typeOfEoats === "Elektrisch"}
              onChange={handleChange}
            />
            <label htmlFor="typeOfEoats1">Elektrisch</label>
          </div>
          <div>
            <input
              type="radio"
              id="typeOfEoats2"
              name="typeOfEoats"
              value="Pneumatisch"
              checked={data.typeOfEoats === "Pneumatisch"}
              onChange={handleChange}
            />
            <label htmlFor="typeOfEoats2">Pneumatisch</label>
          </div>
          <div>
            <input
              type="radio"
              id="typeOfEoats3"
              name="typeOfEoats"
              value="Vakuum"
              checked={data.typeOfEoats === "Vakuum"}
              onChange={handleChange}
            />
            <label htmlFor="typeOfEoats3">Vakuum</label>
          </div>
          <div>
            <input
              type="radio"
              id="typeOfEoats4"
              name="typeOfEoats"
              value="Magnetisch"
              checked={data.typeOfEoats === "Magnetisch"}
              onChange={handleChange}
            />
            <label htmlFor="typeOfEoats4">Magnetisch</label>
          </div>
        </div>
        <span className="wh2-big-subtitle">Anzahl der EOAT’s:</span>
        <div className="wh2-form-input-row">
          <div>
            <input
              type="radio"
              id="numberOfEoats1"
              name="numberOfEoats"
              value="1"
              checked={data.numberOfEoats === "1"}
              onChange={handleChange}
            />
            <label htmlFor="numberOfEoats1">1</label>
          </div>
          <div>
            <input
              type="radio"
              id="numberOfEoats2"
              name="numberOfEoats"
              value="2"
              checked={data.numberOfEoats === "2"}
              onChange={handleChange}
            />
            <label htmlFor="numberOfEoats2">2</label>
          </div>
        </div>
        <SkipButton />
      </div>
      <div className="wh2-form-page-buttons">
        <PrevButton />
        <NextButton />
      </div>
    </div>
  );

  return content;
};
export default Step3;
