import useFormContext from "../hooks/useFormContext";
import NextButton from "../components/NextButton";
import PrevButton from "../components/PrevButton";
import SkipButton from "./SkipButton";
import PopUp from "./PopUp";
import { useState } from "react";

const Step2 = () => {
  const { data, handleChange } = useFormContext();
  const [showPopup, setShowPopup] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const content = (
    <div className="wh2-form-main-container">
      <div className="wh2-form-input">
        <label>Möchtest du ein <button onClick={(event) => {
          event.preventDefault(); 
          event.stopPropagation(); 
          setShowPopup(true);
        }} className="wh2-eoat-text">
          EOAT
        </button> von Kilivations?</label>
        {showPopup && (
        <PopUp popupClass='wh2-mt-form-popup-2' closeClick={handleClosePopup} title='"EOAT steht für "End Of Arm Tooling". Dies bezieht sich auf die Ausrüstung oder Werkzeuge, die am Ende eines Roboterarms angebracht werden. EOAT ist ein entscheidender Bestandteil in der Robotik, da es dem Roboterarm ermöglicht, spezifische Aufgaben auszuführen, wie zum Beispiel das Greifen, Schweißen, Schneiden, Malen, Schrauben, und viele andere Funktionen, abhängig von der jeweiligen Anwendung und Industrie"' />
        )}
        <div className="wh2-form-input-row">
          <div>
            <input
              type="radio"
              id="wantEoat1"
              name="wantEoat"
              value="Ja"
              checked={data.wantEoat === "Ja"}
              onChange={handleChange}
            />
            <label htmlFor="wantEoat1">Ja</label>
          </div>
          <div>
            <input
              type="radio"
              id="wantEoat2"
              name="wantEoat"
              value="Nein"
              checked={data.wantEoat === "Nein"}
              onChange={handleChange}
            />
            <label htmlFor="wantEoat2">Nein</label>
          </div>
        </div>
        <SkipButton />
      </div>
      <div className="wh2-form-page-buttons">
        <PrevButton />
        <NextButton />
      </div>
    </div>
  );

  return content;
};
export default Step2;
