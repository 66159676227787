import useFormContext from "../hooks/useFormContext";
import weiterArrow from "../assets/weiter-arrow.svg";

const PrevButton = () => {
  const { disablePrev, handlePrev } = useFormContext();

  return (
    <button
      className="wh2-form-prev-button"
      onClick={handlePrev}
      disabled={disablePrev}
    >
     Zurück  <img className="wh2-form-button-img" src={weiterArrow} alt="weiter-arrow" /> 
    </button>
  );
};

export default PrevButton;
