import popupInfo from '../assets/popup-info.svg';
import closeIcon from '../assets/close-popup.svg';
import useFormContext from "../hooks/useFormContext";
import { useEffect, useState } from 'react';


const PopUp = ({title, buttonText, closeClick, popupClass}) => {

    const { skipToLast } = useFormContext();
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        setShowButton(buttonText !== undefined && buttonText !== '');
    }, [buttonText]);

  return (
    <div className={popupClass}>
        <img className="wh2-mt-form-info-icon" src={popupInfo} alt='popup-info'/>
        <div className="wh2-mt-form-popup-content">
            <p className="wh2-form-subtitle">{title}</p>
        {showButton && (  
            <button className='wh2-form-next-button' onClick={skipToLast}>{buttonText}</button>
        )}
                
        </div>
        <button className='wh2-form-arrow-button' onClick={closeClick}>
            <img className="wh2-mt-form-close-icon" src={closeIcon} alt='close-popup'/>
        </button>
           
    </div>
  );
};

export default PopUp;
