import useFormContext from "../hooks/useFormContext";
import stationaryHeightImg from "../assets/stationary-height.png";
import NextButton from "../components/NextButton";
import { useEffect, useState } from "react";
import PrevButton from "../components/PrevButton";

const Step7 = () => {
  const { data, handleChange } = useFormContext();
  const [trackColor, setTrackColor] = useState('');

  useEffect(() => {
    const percentage = (data.robotHeight / 1200) * 100;
    const newTrackColor = `linear-gradient(90deg, #94C11F ${percentage}%, rgba(0, 0, 0, 0.5) ${percentage}%)`;
    setTrackColor(newTrackColor);
  }, [data.robotHeight]);

  const content = (
    <div className="wh2-form-main-container">
      <div className="wh2-form-input">
        <label htmlFor="robotHeight">
          Auf welcher Höhe soll der Roboter montiert werden
        </label>
        <span className="wh2-big-subtitle">
          Stationär (Robotersäulen Slim und Integrated), in welcher Höhe?
        </span>
      </div>
      <div className="wh2-step7-range">
        <div className="wh2-form-input wh2-horizontal-range-container">
          <input
            className="wh2-horizontal-range"
            type="range"
            id="robotHeight"
            name="robotHeight"
            min="100"
            max="1200"
            step="10"
            value={data.robotHeight}
            onChange={handleChange}
            style={{ background: trackColor }}
          />
          <span className="wh2-form-range-value">{data.robotHeight} mm</span>
        </div>
        <div className="wh2-form-stationary-img">
          <img src={stationaryHeightImg} alt="Stationary Dimensions " />
        </div>
      </div>
      <div className="wh2-form-page-buttons">
        <PrevButton />
        <NextButton />
      </div>
    </div>
  );

  return content;
};
export default Step7;
