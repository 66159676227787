import useFormContext from "../hooks/useFormContext";
import PrevButton from "../components/PrevButton";
import thankYouImg from "../assets/kilivation_thank_you.svg";

const Step9 = () => {
  const { data, handleChange, submissionSuccess } = useFormContext();

  const handlePhoneNumberChange = (event) => {
    const { name, value } = event.target;

    // Remove all non-digit characters from the input
    const cleanedValue = value.replace(/\D/g, "");

    // Limit the phone number to 12 digits
    const limitedValue = cleanedValue.slice(0, 15);

    const formattedValue = limitedValue;

    handleChange({ target: { name, value: formattedValue } });
  };

  const handleKeyDown = (e) => {
    if (
      !/[\d()\-+\s]/.test(e.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const redirectToHomepage = () => {
    window.location.href = "/"; 
  };

  const content = (
    <div className="wh2-form-main-container wh2-form-kontakt-box">
      <label className="wh2-form-kontakt-label">Kontaktinformationen:</label>

      <div className="wh2-form-input wh2-form-kontakt-two">
        <input
          type="text"
          id="wh2-form-kontakt-name"
          name="wh2FormName"
          value={data.wh2FormName}
          onChange={handleChange}
          placeholder="VORNAME"
        />
        <input
          type="text"
          id="wh2-form-kontakt-last-name"
          name="wh2FormLastName"
          value={data.wh2FormLastName}
          onChange={handleChange}
          placeholder="NACHNAME"
          required
        />
      </div>

      <div className="wh2-form-input wh2-form-kontakt">
        <input
          type="text"
          id="wh2-form-company-name"
          name="wh2FormCompanyName"
          value={data.wh2FormCompanyName}
          onChange={handleChange}
          placeholder="FIRMENNAME"
          required
        />
      </div>

      <div className="wh2-form-input wh2-form-kontakt-two">
        <input
          type="email"
          id="wh2-form-kontakt-email"
          name="wh2FormEmail"
          value={data.wh2FormEmail}
          onChange={handleChange}
          placeholder="EMAIL ADRESSE"
          required
        />
        <input
          type="tel"
          id="wh2-form-kontakt-phoneNumber"
          name="wh2FormPhone"
          value={data.wh2FormPhone}
          onChange={handlePhoneNumberChange}
          onKeyDown={handleKeyDown}
          placeholder="TELEFONNUMER"
          required
        />
      </div>

      <div className="wh2-form-input wh2-form-kontakt">
        <textarea
          id="wh2-form-kontakt-message"
          name="wh2FormMessage"
          value={data.wh2FormMessage}
          onChange={handleChange}
          placeholder="NACHRICHT"
        />
      </div>

      <div className="wh2-form-input wh2-form-kontakt">
        <label className="wh2-form-acceptance">
          <input
            type="checkbox"
            id="wh2-form-acceptance-checkbox"
            name="wh2FormAcceptance"
            value={false}
            checked={data.wh2FormAcceptance}
            onChange={handleChange}
            required
          />
          Hiermit akzeptiere ich die{" "}
          <a href="/datenschutz/" target='_blank'>Datenschutzerklärung </a>
        </label>
      </div>

      <div className="wh2-form-page-buttons">
        <PrevButton />
        <button type="submit" className="wh2-form-submit-button">
        Einreichen
        </button>
      </div>
    </div>
  );

  const popupContent = (
    <div className="wh2-form-main-container wh2-form-popup-content">
      <div className="wh2-form-input">
        <img src={thankYouImg} alt="successfull-submission" />
        <h1>Vielen Dank!</h1>
        <label>Wir werden uns bald mit Ihnen in Verbindung setzen</label>
        <button className="wh2-form-submit-button" onClick={redirectToHomepage}>
          Startseite
        </button>
      </div>
    </div>
  );

  return <div>{submissionSuccess ? popupContent : content}</div>;
};

export default Step9;
