import useFormContext from "../hooks/useFormContext";
import NextButton from "../components/NextButton";
import PrevButton from "../components/PrevButton";
import SkipButton from "./SkipButton";

const Step4 = () => {
  const { data, handleChange } = useFormContext();

  const content = (
    <div className="wh2-form-main-container">
      <div className="wh2-form-input">
        <label>Roboter mit Kamera?</label>
        <div className="wh2-form-input-row">
          <div>
            <input
              type="radio"
              id="robotWithKamera1"
              name="robotWithKamera"
              value="Mit Kamera"
              checked={data.robotWithKamera === "Mit Kamera"}
              onChange={handleChange}
            />
            <label htmlFor="robotWithKamera1">Mit Kamera</label>
          </div>
          <div>
            <input
              type="radio"
              id="robotWithKamera2"
              name="robotWithKamera"
              value="Ohne Kamera"
              checked={data.robotWithKamera === "Ohne Kamera"}
              onChange={handleChange}
            />
            <label htmlFor="robotWithKamera2">Ohne Kamera</label>
          </div>
        </div>
        <SkipButton />
      </div>
      <div className="wh2-form-page-buttons">
        <PrevButton />
        <NextButton />
      </div>
    </div>
  );

  return content;
};
export default Step4;
