import useFormContext from "../hooks/useFormContext";
import NextButton from "../components/NextButton";
import PrevButton from "../components/PrevButton";
import SkipButton from "./SkipButton";

const Step5 = () => {
  const { data, handleChange } = useFormContext();

  const content = (
    <div className="wh2-form-main-container">
      <div className="wh2-form-input">
        <label>
          Sollen wir die Komponenten vormontiert oder in Einzelteilen zusenden?
        </label>
        <div className="wh2-form-input-row">
          <div>
            <input
              type="radio"
              id="robotWithAssembly1"
              name="robotWithAssembly"
              value="Vormontiert"
              checked={data.robotWithAssembly === "Vormontiert"}
              onChange={handleChange}
            />
            <label htmlFor="robotWithAssembly1">Vormontiert</label>
          </div>
          <div>
            <input
              type="radio"
              id="robotWithAssembly2"
              name="robotWithAssembly"
              value="In Einzelteilen"
              checked={data.robotWithAssembly === "In Einzelteilen"}
              onChange={handleChange}
            />
            <label htmlFor="robotWithAssembly2">In Einzelteilen</label>
          </div>
        </div>
        <SkipButton />
      </div>
      <div className="wh2-form-page-buttons">
        <PrevButton />
        <NextButton />
      </div>
    </div>
  );

  return content;
};
export default Step5;
