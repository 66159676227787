import React from "react";
import useFormContext from "../hooks/useFormContext";
import NextButton from "../components/NextButton";
import PrevButton from "../components/PrevButton";
import SkipButton from "./SkipButton";

const Step8 = () => {
  const { data, handleChange } = useFormContext();

  // Define an array of 20 options
  const options = [
    "Leitungsverlängerung",
    "7. Achse",
    "Schüttgutvereinzelung",
    "BinPicking",
    "Schulung ",
    "Inbetriebnahme ",
    "Schaltschrank ",
    "Druckluftaufbereitung",
    "Risikobewertung & CE-Zertifizierung",
    "Weitere EOAT für Werkzeugwechsler",
    "Beratung",
    "Ersatzteilpaket",
    "Wartungsvertrag",
    "Maschinenschnittstelle",
    "Feldbus",
    "Schutzzaun",
    "Roboterzelle",
    "Fördertechnik",
    "Vorratssystem",
    "Ventilinsel",
    "Kabelführung am Roboter",
  ];

  const content = (
    <div className="wh2-form-main-container">
      <label style={{ marginRight: "15%" }}>Was wir auch anbieten:</label>
      <div className="wh2-form-input wh2-form-checkboxes">
        {options.map((option, index) => (
          <div key={index}>
            <input
              type="checkbox"
              id={`otherServices${index}`}
              name="otherServices"
              value={option}
              checked={data.otherServices.includes(option)}
              onChange={handleChange}
            />
            <label htmlFor={`otherServices${index}`}>{option}</label>
          </div>
        ))}
      </div>
      <SkipButton />
     
      <div className="wh2-form-page-buttons">
        <PrevButton />
        <NextButton />
      </div>
    </div>
  );

  return content;
};

export default Step8;
