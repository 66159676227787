import useFormContext from "../hooks/useFormContext";
import NextButton from "../components/NextButton";
import PopUp from '../components/PopUp';
import { useEffect, useState } from "react";

const Step1 = () => { 
  const maxWeight = 20;
  const { data, handleChange } = useFormContext();
  const [trackColor, setTrackColor] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const weightValue = Number(data.robotWeight); 
    const percentage = (weightValue / maxWeight) * 100;
    const newTrackColor = `linear-gradient(90deg, #94C11F ${percentage}%, rgba(0, 0, 0, 0.5) ${percentage}%)`;
    setTrackColor(newTrackColor);

    if (weightValue === maxWeight) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
    
  }, [data.robotWeight]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const content = (
    <div className="wh2-form-main-container">
      <div className="wh2-form-input">
        <label htmlFor="robotWeight">Wie schwer ist dein Bauteil?</label>
        <span className="wh2-form-subtitle">
          Gib das Gewicht an, dass dein Roboter tragen muss, um das perfekte
          Modell für dich zu bestimmen!
        </span>
        <input
          type="range"
          id="robotWeight"
          name="robotWeight"
          min="0"
          max="20"
          step="1"
          value={data.robotWeight}
          onChange={handleChange}
          style={{ background: trackColor }}
        />
        <span className="wh2-form-range-value">{data.robotWeight} kg</span>
      </div>

      {showPopup && (
        <PopUp popupClass='wh2-mt-form-popup' closeClick={handleClosePopup} title='Es soll ein bisschen mehr sein? Hohe Handlingsgewichte steigern den Anspruch an Automatisierungsprojekte und werden von uns individuell Beraten, bitte nehmen Sie Kontakt mit uns auf damit wir Ihnen eine passende Lösung anbieten können' buttonText='jetzt beraten lassen'/>
      )}

      <div className="wh2-form-input">
        <label>Hersteller</label>
        <div className="wh2-form-input-row">
          <div>
            <input
              type="radio"
              id="hersteller1"
              name="hersteller"
              value="FANUC"
              checked={data.hersteller === "FANUC"}
              onChange={handleChange}
            />
            <label htmlFor="hersteller1">FANUC</label>
          </div>
          <div>
            <input
              type="radio"
              id="hersteller2"
              name="hersteller"
              value="Universal Robots"
              checked={data.hersteller === "Universal Robots"}
              onChange={handleChange}
            />
            <label htmlFor="hersteller2">Universal Robots</label>
          </div>
        </div>
        <span className="wh2-form-subtitle">
          Gib das Gewicht an, dass dein Roboter tragen muss, um das perfekte
          Modell für dich zu bestimmen!
        </span>
      </div>
      
      <NextButton /> 

    </div>

  );

  return content;
};
export default Step1;
