import { createContext, useState } from "react";

const FormContext = createContext({});

export const FormProvider = ({ children }) => {
  const title = {
    0: "Step-1",
    1: "Step-2",
    2: "Step-3",
    3: "Step-4",
    4: "Step-5",
    5: "Step-6",
    6: "Step-7",
    7: "Step-8",
    8: "Step-9",
  };

  const getHomePageUrl = () => {
    return window.location.origin + "/";
  };

  const customAjaxUrl = getHomePageUrl() + "wp-admin/admin-ajax.php";

  const [page, setPage] = useState(0);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [data, setData] = useState({
    robotWeight: "0",
    hersteller: "FANUC",
    wantEoat: "Ja",
    typeOfEoats: "Elektrisch",
    numberOfEoats: "1",
    robotWithKamera: "Mit Kamera",
    robotWithAssembly: "Vormontiert",
    robotWithMobile: "Mobil",
    robotHeight: "10",
    otherServices: [],
    kapseleOption: "Kapsele",
    stationaryOption: "Slim",
    wh2FormAcceptance: false,
    wh2FormName: "",
    wh2FormEmail: "",
    wh2FormPhone: "",
    wh2FormMessage: "",
    isSkipLast: false
  });



  const handleChange = (e) => {
    const { type, name, value, checked } = e.target;

    setData((prevData) => {
      if (type === "checkbox") {
        // Check if prevData[name] is an array
        if (Array.isArray(prevData[name])) {
          const updatedServices = [...prevData[name]];
          if (checked) {
            updatedServices.push(value);
          } else {
            const index = updatedServices.indexOf(value);
            if (index !== -1) {
              updatedServices.splice(index, 1);
            }
          }
          return { ...prevData, [name]: updatedServices };
        } else {
          // If it's not an array, handle it as a single value
          return { ...prevData, [name]: checked };
        }
      } else {
        return { ...prevData, [name]: value };
      }
    });
  };

  const handleNext = () => {
    const totalPages = Object.keys(title).length - 1; 

    if (data.wantEoat === "Nein" && page === 1) {
      setPage(3);
    } else if (data.robotWithMobile === "Mobil" && page === 5) {
      setPage(7);
    } else if (page < totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  const skipToLast = () => {
    const totalPages = Object.keys(title).length - 1; 
    setPage(totalPages);
    setData(prevData => ({ ...prevData, isSkipLast: true }));
  };

  const handlePrev = () => {
    const totalPages = Object.keys(title).length - 1; 

    if (submissionSuccess && page === totalPages) {
      return;
    }
    console.log('submissionSuccess' + submissionSuccess);

    if (data.wantEoat === "Nein" && page === 3) {
      setPage(1);
    } else if (data.robotWithMobile === "Mobil" && page === 7) {
      setPage(5);
    } else if (page > 0) {
      setPage((next) => next - 1);
    }
    if (data.isSkipLast === true) {
      setData(prevData => ({ ...prevData, isSkipLast: false }));
    }

   

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(customAjaxUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        action: "send_custom_email", 
        formData: JSON.stringify(data), 
      }),
    })
      .then((response) => response.text())
      .then((responseData) => {
        console.log("Success:", responseData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setSubmissionSuccess(true);
  }

  console.log(data.isSkipLast);


  const { ...requiredInputs } = data;

  const canSubmit =
    [...Object.values(requiredInputs)].every(Boolean) &&
    page === Object.keys(title).length - 1;

  const canNextPage1 =
    data.robotWeight !== "0" &&
    (data.hersteller === "FANUC" || data.hersteller === "Universal Robots");

  const canNextPage2 = data.wantEoat !== "";

  const disablePrev = page === 0 ||  page === Object.keys(title).length - 1 && submissionSuccess;

  const disableNext =
    page === Object.keys(title).length - 1 ||
    (page === 0 && !canNextPage1) ||
    (page === 1 && !canNextPage2);

  const prevHide = page === 0 && "remove-button";

  const nextHide = page === Object.keys(title).length - 1 && "remove-button";

  const submitHide = page !== Object.keys(title).length - 1 && "remove-button";


  return (
    <FormContext.Provider
      value={{
        title,
        page,
        setPage,
        data,
        setData,
        canSubmit,
        handleChange,
        handleNext,
        handlePrev,
        handleSubmit,
        skipToLast,
        submissionSuccess,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
