import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import useFormContext from "../hooks/useFormContext";




const FormInputs = () => {
  const { page } = useFormContext();

  const display = {
    0: <Step1 />,
    1: <Step2 />,
    2: <Step3 />,
    3: <Step4 />,
    4: <Step5 />,
    5: <Step6 />,
    6: <Step7 />,
    7: <Step8 />,
    8: <Step9 />
  };



  const content = (
    <div className="wh2-form-inputs-box form-inputs">
      {display[page]}
    </div>
  );

  return content;
};
export default FormInputs;
